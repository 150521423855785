<template>
    <section class="container-xxl">
        <div class="sect-base">
            <div class="row">
                <div class="col-12">
                    <h2 class="h2 ff-m fw-6 text-start mb-4">Стать партнером</h2>
                </div>
                <div class="col-12">
                    <div class="ff-r fs-l fw-4 text-start">
                        <p>
                            Формирование в обществе устойчивого уважения к закону и преодоление правового нигилизма —
                            задача непростая, но мы верим, что вместе мы сможем сделать это качественно и в соразмерно
                            короткие сроки.
                        </p>
                        <p>Мы ценим любой вклад в общее дело.</p>
                        <p>
                            <strong>
                                По вопросам сотрудничества направляйте обращения по адресу электронной почты:
                            </strong>
                            <a href="mailto:info@legalculture.ru">info@legalculture.ru</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="container-xxl">
        <div class="sect-base">
            <div class="row">
                <div class="col-12">
                    <h2 class="h2 ff-m fw-6 text-start mb-4">Волонтерство</h2>
                </div>
                <div class="col-12">
                    <div class="ff-r fs-l fw-4 text-start">
                        <p>
                            Волонтеры — люди, которые на безвозмездной основе участвуют в общественно полезной
                            деятельности. Волонтёрство — это вклад в общее дело, развитие культуры, общества, улучшение
                            жизни.
                        </p>
                        <p>
                            Основная особенность Волонтеров Фонда — наличие высшего юридического образования, опыта
                            работы в правовой сфере, желания избавить людей от правового нигилизма, готовности на
                            безвозмездной основе помогать нуждающимся советом и делом.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="container-xxl">
        <div class="sect-base">
            <div class="bg-dagray p-4">
                <div class="row">
                    <div class="col-12 col-lg-8">
                        <h5 class="h5 ff-m fw-6 text-start text-white mb-4">
                            Будем рады видеть Вас среди наших Волонтеров
                            Просто заполните обратную форму
                        </h5>
                    </div>
                    <div class="col-12">
                        <form-base/>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import FormBase from "@/components/FormBase";

    export default {
        name: 'PartnershipPage',
        components: {FormBase},
    }
</script>